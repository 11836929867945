<template>
<div
  class="fun_bar_finance d-flex align-center"
>
  <div class="text">财务标记</div>
  <el-switch
    v-model="finance"
    active-color="#D32F2F"
    inactive-color="#FFEBEE"
  ></el-switch>
</div>
</template>

<script>
export default {
  data () {
    return {
      finance: false
    }
  }
}
</script>

<style scoped lang="scss">
.fun_bar_finance {
  .text{
    font-size: 12px;
    color: #666666;
    padding-right: 6px;
    line-height: 19px;
  }
}
</style>
<style>
.fun_bar_finance .el-switch__core{
  height: 16px;
  border-radius: 8px;
  width: 33px!important;
}
.fun_bar_finance .el-switch__core::after{
  width: 12px;
  height: 12px;
}
.fun_bar_finance .el-switch.is-checked .el-switch__core::after{
  margin-left: -13px;
}
</style>