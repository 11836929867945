<template>
  <div
    class="components_task_fun_bar_color_page d-flex align-center"
  >
    <template
      v-for="(item, index) in list"
    >
      <div class="color bbox cp"
        :key="index"
        :style="{'background-color' : item.type? item.color :'transparent', 'border-color': item.color}"
        @click="handleChange(item)"
      >
        <div class="hint">{{item.type?'移除':'添加'}}{{item.text}}</div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    task: {
      type: Object,
      default: function(){
        return {}
      },
    },
    vip: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      list: [
        {
          color: '#FD5646',
          type: 0,
          text: '红色',
        },
        {
          color: '#FFB038',
          type: 0,
          text: '橙色',
        },
        {
          color: '#4C8CEF',
          type: 0,
          text: '蓝色',
        },
        {
          color: '#72CB6C',
          type: 0,
          text: '绿色',
        },
        {
          color: '#9C9C9F',
          type: 0,
          text: '灰色',
        },
      ],
    }
  },
  methods: {
    handleChange(item){
      // const {vip} = this;
      // if(vip === false){
      //   this.$notice({desc: '该操作为会员权益，当前用户暂无权限'});
      //   return;
      // }
      this.$set(item, 'type', item.type? 0: 1);
      this.setColor();
    },
    async setColor(){
      // console.log(this);
      const {task_config} = this.$store.state;
      const {edit} = task_config;
      let color = '';
      this.list.forEach(item=>{
        if(item.type){
          color += color ? `,${item.color}`: item.color;
        }
      })
      if(edit){
        // 编辑任务时才实时修改
        
        this.$api.moduleTask.setColor({
          taskId: this.task.task.id,
          color
        })
      }
      // 保存在task_config中
      this.$store.commit('set_task_config', Object.assign({}, this.$store.state.task_config, {color}));
      
    },
    initList(){
      const {task_config} = this.$store.state;
      const {color} = task_config;
      const {list} = this;
      // if(task.task){
      //   const {colour} = task.task;
        // #FD5646,#FFB038,#4C8CEF,#72CB6C,#9C9C9F
        if(color){
          const arr = color.split(',');
          arr.forEach(item=>{
            const aim = list.find(unit=>{
              return unit.color === item;
            })
            if(aim !== undefined){
              this.$set(aim, 'type', 1);
            }
          })
        }
      // }
    },
    init(){
      this.initList();
    },
  },
  mounted(){
    this.init();
  }

}
</script>
<style lang="scss" scoped>
.components_task_fun_bar_color_page{
  .color{
    width: 16px;
    height: 16px;
    margin: 0 3px;
    border: 2px solid transparent;
    border-radius: 2px;
    position: relative;
    z-index: 4;
    &:hover{
      .hint{
        opacity: 1;
        transform: translateX(-50%) scaleY(1);
      }
    }
  }
  .hint{
    @include transition;
    transition-delay: 0.2s;
    transform-origin: top center;
    opacity: 0;
    position: absolute;
    z-index: 5;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 30px;
    background: #232D37;
    border-radius: 4px;
    padding: 0 10px;
    word-break: keep-all;
    bottom: -40px;
    left:50%;
    transform: translateX(-50%) scaleY(0);
    &::before{
      position: absolute;
      left: 50%;
      top:-8px;
      content: '';
      display: block;
      width:0;
      height: 0;
      border: 4px solid transparent;
      border-bottom: 4px solid #232D37;
      transform: translateX(-4px);
    }
  }
}
</style>