var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fun_bar_finance d-flex align-center" },
    [
      _c("div", { staticClass: "text" }, [_vm._v("财务标记")]),
      _c("el-switch", {
        attrs: { "active-color": "#D32F2F", "inactive-color": "#FFEBEE" },
        model: {
          value: _vm.finance,
          callback: function ($$v) {
            _vm.finance = $$v
          },
          expression: "finance",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }