<template>
  <div
    class="components_task_fun_bar_privacy_page d-flex align-center"
  >
    <div class="text">隐私模式</div>
    <el-switch
      v-model="privacy"
      active-color="#0054A7"
      inactive-color="#CCCCCC"
      @change="handleChange();"  
    >
    </el-switch>
    <div class="question_mark cp">
      <div class="hint">
        <div class="text">隐私模式未开启时，全部组员可见</div>
        <div class="text">隐私模式开启后，仅参与人可见</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    task: {
      type: Object,
      default: function(){
        return {}
      },
    },
    vip: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      privacy: false,
    }
  },
  methods: {
    async handleChange(){
      const { vip } = this;

      // const already = this.$api.user.membershipPrivileges({hint: '该操作为会员权益，当前用户暂无权限'});
      // if(vip === false){
      //   this.$notice({desc: '该操作为会员权益，当前用户暂无权限'});
      //   this.privacy = !this.privacy;
      //   return;
      // }
      const {commit, state} = this.$store;
      const {task_config} = state;
      const res = await this.setPrivacy();
      // if(res.message === 'successs'){
      //   // 更新操作区隐私图标
      //   const { my_task_list} = this.$store.state;
      //   const {task, privacy} = this;
      //   // console.log({privacy})
      //   if(my_task_list && my_task_list.length){
      //     my_task_list.forEach(item=> {
      //       if(item.id == task.task.id){
      //         this.$set(item, 'hide', privacy);
      //       }
      //     })
      //   }
      // }
      if(res === false){
        this.privacy = !this.privacy;
        commit('set_task_config', Object.assign({},task_config, {privacy:this.privacy}));
      }
    },
    async setPrivacy(){
      const {$store, privacy, task} = this;
      const {commit, state} = $store;
      const {task_config} = state;
      const {edit } = task_config;
      if(edit){
        const res = await this.$api.moduleTask.setPrivacy({
          taskId: task.task.id,
          privacy,
        })
        return res;

      }
      commit('set_task_config', Object.assign({},task_config, {privacy}));
      return true;
    },
    init(){
      const {task_config} = this.$store.state;
      const {privacy} = task_config;
      // const {task}= this;
      // if(task.task){
        this.privacy = privacy;
      // }
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.components_task_fun_bar_privacy_page{
  // padding: 0 10px;
  // height: 24px;
  // border-left: 1px solid #f4f4f4;
  // border-right: 1px solid #f4f4f4;
  .text{
    font-size: 12px;
    color: #666666;
    padding-right: 6px;
    line-height: 19px;
  }
  .question_mark{
    @include background(16px, 16px);
    width: 20px;
    height: 20px;
    background-image: url(~@/assets/images/task/question_mark.png);
    margin-left: 5px;
    position: relative;
    z-index: 4;
    &:hover{
      .hint{
        opacity: 1;
        transform: translateX(-50%)  scaleY(1);
      }
    }
  }
  .hint{
    @include transition;
    transition-delay: 0.2s;
    transform-origin: top center;
    opacity: 0;
    position: absolute;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 30px;
    background: #232D37;
    border-radius: 4px;
    padding: 7px 10px;
    word-break: keep-all;
    top: 26px;
    left:50%;
    transform: translateX(-50%) scaleY(0);
    z-index: 5;
    .text{
      word-break: keep-all;
      font-size: 12px;
      color:#fff;
      line-height: 20px;
      white-space:nowrap; 
    }
    &::before{
      position: absolute;
      left: 50%;
      top:-8px;
      content: '';
      display: block;
      width:0;
      height: 0;
      border: 4px solid transparent;
      border-bottom: 4px solid #232D37;
      transform: translateX(-4px);
    }
  }
}
</style>
<style>
.components_task_fun_bar_privacy_page .el-switch__core{
height: 16px;
  border-radius: 8px;
  width: 33px!important;
}
.components_task_fun_bar_privacy_page .el-switch__core::after{
  width: 12px;
  height: 12px;
}
.components_task_fun_bar_privacy_page .el-switch.is-checked .el-switch__core::after{
  margin-left: -13px;
}
</style>