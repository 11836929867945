var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_fun_bar_privacy_page d-flex align-center" },
    [
      _c("div", { staticClass: "text" }, [_vm._v("隐私模式")]),
      _c("el-switch", {
        attrs: { "active-color": "#0054A7", "inactive-color": "#CCCCCC" },
        on: {
          change: function ($event) {
            return _vm.handleChange()
          },
        },
        model: {
          value: _vm.privacy,
          callback: function ($$v) {
            _vm.privacy = $$v
          },
          expression: "privacy",
        },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "question_mark cp" }, [
      _c("div", { staticClass: "hint" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v("隐私模式未开启时，全部组员可见"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v("隐私模式开启后，仅参与人可见"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }