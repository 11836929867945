var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_fun_bar_color_page d-flex align-center" },
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          _c(
            "div",
            {
              key: index,
              staticClass: "color bbox cp",
              style: {
                "background-color": item.type ? item.color : "transparent",
                "border-color": item.color,
              },
              on: {
                click: function ($event) {
                  return _vm.handleChange(item)
                },
              },
            },
            [
              _c("div", { staticClass: "hint" }, [
                _vm._v(_vm._s(item.type ? "移除" : "添加") + _vm._s(item.text)),
              ]),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }